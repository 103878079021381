<template>
  <b-container fluid class="component-reports-performance-budget-report-barchart">
    <bar-chart
      :chart-data="this.chartData"
      :options="this.chartOptions"
      :chart-id="'reports-performance-budget-report-barchart'"
      :width="100"
      :height="400"
      :css-classes="''"
      :plugins="[]"
    >
    </bar-chart>
  </b-container>
</template>

<script>
import BarChart from '@/components/chartjs/BarChart'
import chartOptions from '@/mixins/defaultChartJsOptions'
import formatNumberToFull from '@/assets/filters/formatNumberToFull'
import SpirectaReportChartMixin from '@/views/reports/_inc/SpirectaReportChartMixin'

export default {
  name: 'PerformanceBudgetReportBarchart',
  components: { BarChart },
  mixins: [chartOptions, SpirectaReportChartMixin],
  props: {
    apiData: { type: Object },
    account: { type: Object },
    resolution: { type: String },
    chartToDisplay: { type: String, default: 'period-result-bar-chart' },
    isLoaded: { type: Boolean, default: false }
  },
  data () {
    return {
      chartData: {},
      translationPath: 'reports.budget.performance.charts.'
    }
  },
  methods: {
    fillChart () {
      if (!this.isLoaded || !this.apiData) {
        return false
      }
      const labels = []
      const aBackgroundColor = []
      this.apiData.meta.periods.map(period => {
        labels.push(this.translatePeriodColumnTitleIntoNiceName(period, this.resolution))
      })

      const suffix = ' ' + this.currencySymbol

      this.chartOptions.title.display = false
      this.chartOptions.title.align = 'start'

      this.chartOptions.legend.display = true
      this.chartOptions.tooltips.callbacks.label = function (tooltipItem, data) {
        return data.datasets[tooltipItem.datasetIndex].label + ': ' + formatNumberToFull(tooltipItem.yLabel) + suffix
      }
      this.chartOptions.scales.yAxes[0].ticks.callback = function (value, index, values) {
        return formatNumberToFull(value) + suffix
      }

      /**
       * Result chart
       */
      if (this.chartToDisplay === 'period-result-bar-chart') {
        const dataset = { type: 'bar', data: [], label: this.$t(this.translationPath + 'performance_budget') }

        for (const period in this.apiData.meta.periods) {
          const fAmount = this.apiData.total.periods[this.apiData.meta.periods[period]]
          dataset.data.push(fAmount)
          aBackgroundColor.push(fAmount > 0 ? this.getGreen() : this.getRed())
        }
        dataset.backgroundColor = aBackgroundColor
        this.chartData = { labels: labels, datasets: [dataset] }
      }

      /**
       * Income / Expense-chart
       */
      if (this.chartToDisplay === 'period-income-bar-chart' || this.chartToDisplay === 'period-expense-bar-chart') {
        this.chartOptions.scales.xAxes[0].stacked = true
        this.chartOptions.scales.yAxes[0].stacked = 'income'
        const palette = this.getChartPalette()
        const datasets = []

        const sGroupType = this.chartToDisplay === 'period-income-bar-chart' ? 'income' : 'expense'

        for (const iGroupIdx in this.apiData[sGroupType].groups) {
          const oGroup = this.apiData[sGroupType].groups[iGroupIdx]
          let iTotal = 0
          const dataset = {
            type: 'bar',
            label: this.apiData[sGroupType].groups[iGroupIdx].label,
            data: []
          }

          // Iterate periods
          this.apiData.meta.periods.map(periodIdx => {
            dataset.data.push(oGroup.periods[periodIdx])
            iTotal += oGroup.periods[periodIdx]
          })

          // Only add if value is !== 0
          if (iTotal !== 0) {
            dataset.backgroundColor = palette.shift()
            datasets.push(dataset)
          }
        }
        this.chartData = { labels: labels, datasets: datasets }
      }
    }
  },
  watch: {
    isLoaded: {
      handler: 'fillChart',
      deep: true,
      immediate: true
    },
    chartToDisplay: {
      handler: 'fillChart',
      deep: true,
      immediate: true
    }
  }
}
</script>
