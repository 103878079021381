<template>
  <div class="component-performance-budget-report">
    <!-- START GRADIENT HEADER -->
    <b-container class="main-gradient light" fluid>

      <!-- START BREADCRUMB, TITLE, TABS WRAPPER -->
      <b-container class="main-gradient-header-wrapper pb-5">

        <b-row class="breadcrumb-wrapper" no-gutters>
          <b-col no-gutters cols="12">
            <b-breadcrumb :items="breadcrumbItems" class="dark"></b-breadcrumb>
          </b-col>
          <b-col cols="12">
            <b-col cols="12" sm="4" md="6" lg="7" xl="8" class="pull-left pl-0 pr-0 pr-md-2">
              <h1 class="text-left">{{ $t(translationPath + 'title_report') }}
                <b-badge v-if="bIsFilterApplied" class="h1badge ml-2" variant="warning">{{$t('reports._common.badge_filter_active')}} <b-link href="#" class="text-black" @click.stop="clearFilter">x</b-link></b-badge>
              </h1>
            </b-col>
            <b-col cols="12" sm="8" md="6" lg="5" xl="4" class="pull-left pr-0 pl-0 pl-md-2">
              <period-selector
                v-if="budgetId"
                default-period-type="rolling-3-months"
                :show-resolution="true"
                :default-resolution="resolution"
                :show-period-option-full-period="true"
                :allowed-resolutions="['month', 'year']"
                @period-selected="onPeriodChange"
              />
            </b-col>
          </b-col>
        </b-row>

        <b-row class="main-gradient-tab-menu-wrapper mb-2" no-gutters>
          <b-col no-gutters>
            <performance-budget-report-submenu :active="activeTab" @tab-switched="onTabSwitched" />
          </b-col>
        </b-row>

      </b-container>
      <!-- END BREADCRUMB, TITLE, TABS WRAPPER -->

      <!-- START CHART WRAPPER -->
      <b-row class="main-gradient-chart-wrapper" :class="{'d-none': activeTab === 'filter'}">
        <div class="pb-5" style="width:100%;height:500px;" v-if="activeTab !== 'about'" :key="renderKey">
          <performance-budget-report-barchart
           v-if="chartToDisplay === 'period-result-bar-chart' || chartToDisplay === 'period-income-bar-chart' || chartToDisplay === 'period-expense-bar-chart'"
           :currency="currency"
           :currencySymbol="currencySymbol"
           :apiData="apiData"
           :isLoaded="!busyState"
           :resolution="resolution"
           :chart-to-display="chartToDisplay"
            />

          <performance-budget-report-pie-chart
            v-if="chartToDisplay === 'period-income-groups-pie-chart' || chartToDisplay === 'period-expense-groups-pie-chart' || chartToDisplay === 'period-income-categories-pie-chart' || chartToDisplay === 'period-expense-categories-pie-chart'"
            :currency="currency"
            :currencySymbol="currencySymbol"
            :apiData="apiData"
            :isLoaded="!busyState"
            :resolution="resolution"
            :chart-to-display="chartToDisplay"
          />

          <performance-budget-report-line-chart
            v-if="chartToDisplay === 'period-result-acc-line-chart'"
            :currency="currency"
            :currencySymbol="currencySymbol"
            :apiData="apiData"
            :isLoaded="!busyState"
            :resolution="resolution"
            />

        </div>
      </b-row>
      <!-- END CHART WRAPPER -->

    </b-container>
    <!-- END GRADIENT HEADER -->

    <!-- START PAGE WRAPPER -->
    <b-container class="main-content-wrapper pt-0 transaction-view-wrapper">
      <!-- Table components here -->
      <performance-budget-report-period-table
        v-if="activeTab === 'summary'"
        ref="SummaryTable"
        :isLoaded="!busyState"
        :api-data="apiData"
        :resolution="resolution"
        :start-date="startDate"
        :end-date="endDate"
        :table-type="'groups_performance'"
        :active-chart-option="chartToDisplay"
        :budgets="budgets"
        :budget-id="budgetId"
        @chart-to-display="onChartSwitched"
        @on-group-click="(data) => onTabSwitched(data.type, true)"
        @on-budget-change="onBudgetChange"
      />

      <performance-budget-report-period-table
        v-if="activeTab === 'income'"
        ref="IncomeTable"
        :isLoaded="!busyState"
        :api-data="apiData"
        :resolution="resolution"
        :start-date="startDate"
        :end-date="endDate"
        :table-type="'income'"
        :active-chart-option="chartToDisplay"
        :budgets="budgets"
        :budget-id="budgetId"
        @chart-to-display="onChartSwitched"
        @on-budget-change="onBudgetChange"
      />

      <performance-budget-report-period-table
        v-if="activeTab === 'expense'"
        ref="ExpenseTable"
        :isLoaded="!busyState"
        :api-data="apiData"
        :resolution="resolution"
        :start-date="startDate"
        :end-date="endDate"
        :table-type="'expense'"
        :active-chart-option="chartToDisplay"
        :budgets="budgets"
        :budget-id="budgetId"
        @chart-to-display="onChartSwitched"
        @on-budget-change="onBudgetChange"
      />

      <!-- REPORT FILTER -->
      <report-filter
        v-if="activeTab === 'filter'"
        ref="ReportFilter"
        :filter-data="reportFilterData"
        :show-toast-on-filter-apply="false"
        :disabled="['asset', 'liability', 'person']"
        @filter-applied="onFilterApplied"
        @filter-data-updated="mergeFilterPartials"
        @current-filter-deleted="onFilterDeleted"
      />
    </b-container>
    <!-- END PAGE WRAPPER -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import titleMixins from '@/mixins/title'
import PeriodSelector from '@/components/common/PeriodSelector'
import SpirectaReportMixin from '@/views/reports/_inc/SpirectaReportMixin'
import PerformanceBudgetReportSubmenu from './inc/PerformanceBudgetReportSubmenu'
import PerformanceBudgetReportPeriodTable from './tables/PerformanceBudgetReportPeriodTable'
import PerformanceBudgetReportBarchart from './charts/PerformanceBudgetReportBarchart'
import PerformanceBudgetReportLineChart from './charts/PerformanceBudgetReportLineChart'
import ReportFilter from '@/components/common/ReportFilter.vue'
import PerformanceBudgetReportPieChart from './charts/PerformanceBudgetReportPiechart'

export default {
  name: 'PerformanceBudgetReport',
  mixins: [titleMixins, SpirectaReportMixin],
  components: {
    PeriodSelector,
    PerformanceBudgetReportSubmenu,
    PerformanceBudgetReportPeriodTable,
    PerformanceBudgetReportBarchart,
    PerformanceBudgetReportLineChart,
    PerformanceBudgetReportPieChart,
    ReportFilter
  },
  data () {
    return {
      validRouteParams: ['summary', 'income', 'expense', 'filter'],
      activeTab: 'summary',
      apiData: null,
      bHasAPIDataBeenFetchedOnce: false,
      translationPath: 'reports.budget.performance.',
      busyState: true,
      startDate: null,
      endDate: null,
      resolution: 'month',
      chartToDisplay: 'period-result-bar-chart',
      statsChartToDisplay: 'common_transactions',
      reportFilterData: null,
      bIsFilterApplied: false,
      renderKey: 0,
      budgetId: null,
      budgets: []
    }
  },
  computed: {
    ...mapState('user', ['currentCOA']),
    breadcrumbItems () {
      const aBreadcrumb = [
        { text: this.$t('reports._common.reports_and_insights'), to: '/reports' },
        { text: this.$t('incomes_expenses.submenu.budget'), to: '/reports/budget' },
        { text: '', active: true }
      ]

      return aBreadcrumb
    },
    titleMeta () {
      return this.$t(this.translationPath + 'title')
    },
    currency () {
      return this.currentCOA.currency_iso ? this.currentCOA.currency_iso : ''
    },
    currencySymbol () {
      return this.currentCOA.currency ? this.currentCOA.currency : ''
    }
  },
  methods: {
    async loadData () {
      return new Promise((resolve, reject) => {
        this.busyState = true

        let sApiEndpoint = `${process.env.VUE_APP_ROOT_API}/reports/budget/performance?start_date=${this.startDate}&end_date=${this.endDate}&resolution=${this.resolution}&budget_id=${this.budgetId}`

        // Filter accounts
        const aIncludeIncomeAccounts = []
        const aIncludeExpenseAccounts = []
        if (this.reportFilterData && Object.prototype.hasOwnProperty.call(this.reportFilterData, 'oFormSelectIncome')) {
          this.reportFilterData.oFormSelectIncome.map(a => { aIncludeIncomeAccounts.push(a) })
        }
        if (this.reportFilterData && Object.prototype.hasOwnProperty.call(this.reportFilterData, 'oFormSelectExpense')) {
          this.reportFilterData.oFormSelectExpense.map(a => { aIncludeExpenseAccounts.push(a) })
        }
        if (aIncludeIncomeAccounts.length) {
          sApiEndpoint += '&filter_accounts_income=' + aIncludeIncomeAccounts.join(',')
        }
        if (aIncludeExpenseAccounts.length) {
          sApiEndpoint += '&filter_accounts_expense=' + aIncludeExpenseAccounts.join(',')
        }

        axios.get(sApiEndpoint)
          .then(response => {
            this.apiData = response.data.data
            this.oAccount = this.apiData.account
            this.busyState = false
            this.bHasAPIDataBeenFetchedOnce = true
            resolve(response.data.data)
          })
          .catch(err => {
            console.error(err)
            reject(err)
          })
      })
    },
    async loadBudgets () {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_ROOT_API}/budgets`)
          .then(response => {
            this.budgets = response.data.data

            resolve(response.data.data)
          })
          .catch(err => {
            console.error(err)
            reject(err)
          })
      })
    },
    onTabSwitched (newtab, bScrollToTop = false) {
      this.prevTab = this.activeTab
      this.activeTab = newtab
      if (newtab !== this.$route.params.tab && this.validRouteParams.indexOf(newtab) !== -1) {
        this.$router.push({ params: { tab: newtab } })
      } else if (newtab !== this.$route.params.tab && this.validRouteParams.indexOf(newtab) === -1) {
        this.$router.push({ params: { tab: 'summary' } })
      }

      let sRefTable = ''
      switch (this.activeTab) {
        case 'summary' :
          this.chartToDisplay = 'period-result-bar-chart'
          sRefTable = 'SummaryTable'
          break
        case 'income' :
          this.chartToDisplay = 'period-income-bar-chart'
          sRefTable = 'IncomeTable'
          break
        case 'expense' :
          this.chartToDisplay = 'period-expense-bar-chart'
          sRefTable = 'ExpenseTable'
          break
      }

      if (bScrollToTop) {
        window.scrollTo(0, 0)
      }

      if (this.prevTab === 'filter') {
        this.renderKey++
      }

      /**
       * Handle in nextTick due to v-if directives.
       * this.$refs[sRefTable] is undefined in current tick.
       */
      process.nextTick(() => {
        this.$refs[sRefTable].$changeChart(this.chartToDisplay)
      })
    },
    onFilterApplied (eventData) {
      this.bIsFilterApplied = true
      this.loadData()
        .then(() => {
          this.$bvToast.toast(this.$t('common.filter_settings_description').toString(), {
            title: this.$t('common.filter_settings_title').toString(),
            variant: 'success',
            solid: true
          })
        })
    },
    onFilterDeleted () {
      this.bIsFilterApplied = false
      this.loadData()
    },
    mergeFilterPartials (partialData) {
      if (!this.reportFilterData) {
        this.reportFilterData = null
      }
      this.reportFilterData = Object.assign({}, this.reportFilterData, partialData)
    },
    clearFilter () {
      this.bIsFilterApplied = false
      if (this.$refs.ReportFilter) {
        this.$refs.ReportFilter.reset()
      } else {
        this.reportFilterData.oFormSelectIncome = []
        this.reportFilterData.oFormSelectExpense = []
        this.reportFilterData.iFormSelectFilter = 0
        this.reportFilterData.oFilterLoaded = null
        this.reportFilterData.oFormFilterTitle = ''
        this.reportFilterData.oFormFilterDescription = ''
      }
      this.loadData()
        .then(() => {
          this.$bvToast.toast(this.$t('common.filter_settings_description').toString(), {
            title: this.$t('common.filter_settings_title').toString(),
            variant: 'success',
            solid: true
          })
        })
    },
    onBudgetChange (budgetId) {
      this.budgetId = budgetId
      this.$router.push({ query: { budget: budgetId } })
      this.loadData()
    },
    onChartSwitched (data) {
      this.chartToDisplay = data.chart
    }
  },
  mounted () {
    if (this.validRouteParams.indexOf(this.$route.params.tab) !== -1) {
      this.activeTab = this.$route.params.tab
    } else {
      this.$router.push({ params: { tab: 'summary' } })
    }

    if (this.$route.query.budget) {
      this.budgetId = Number(this.$route.query.budget)
    } else {
      this.budgetId = this.currentCOA.default_budget_id || null
    }
    this.loadBudgets()
  },
  watch: {
    titleMeta: {
      handler () {
        this.setPageTitle(this.titleMeta)
      },
      immediate: true
    },
    'currentCOA.default_budget_id' () {
      if (!this.budgetId) {
        this.budgetId = this.currentCOA.default_budget_id
      }
    }
  }
}
</script>

<style>
.component-performance-budget-report{
  width: 100%;
}
</style>
