<template>
  <div class="component-performance-budget-report-period-table">

    <b-container fluid class="px-0 mt-4 settings-tab">
      <b-row no-gutters>
        <b-col>
          <b-card class="main-gradient-content-card borderlight pt-3 mx-xl-auto">
            <template v-slot:header>
              <b-row no-gutters v-show="isLoaded">
                <b-col cols="6">
                  <h2>{{ $t(translationPath + 'title_' + tableType,{start_date: startDate, end_date: endDate}) }}</h2>
                </b-col>
                <b-col cols="6" class="text-right">
                  <!-- Toggle columns -->

                  <!-- Toggle result charts -->
                  <b-dropdown right
                              :text="$t('common.toggle_chart') + ' | ' + $t( translationPath + 'charts.'+showChart)"
                              variant='outline-secondary'
                              class="float-right mr-3 mb-2 spirecta-toggle-wrapper"
                              size="sm"
                  >
                    <b-dropdown-item @click="toggleChart('period-result-bar-chart')" v-bind:class="{ active: showChart === 'period-result-bar-chart' }">{{$t( translationPath + 'charts.period-result-bar-chart')}}</b-dropdown-item>
                    <b-dropdown-item @click="toggleChart('period-result-acc-line-chart')" v-bind:class="{ active: showChart === 'period-result-acc-line-chart' }">{{$t( translationPath + 'charts.period-result-acc-line-chart')}}</b-dropdown-item>
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-item @click="toggleChart('period-income-bar-chart')" v-bind:class="{ active: (showChart === 'period-income-bar-chart') }">{{$t(translationPath + 'charts.period-income-bar-chart')}}</b-dropdown-item>
                    <b-dropdown-item @click="toggleChart('period-income-groups-pie-chart')" v-bind:class="{ active: (showChart === 'period-income-groups-pie-chart') }">{{$t(translationPath + 'charts.period-income-groups-pie-chart')}}</b-dropdown-item>
                    <b-dropdown-item @click="toggleChart('period-income-categories-pie-chart')" v-bind:class="{ active: (showChart === 'period-income-categories-pie-chart') }">{{$t(translationPath + 'charts.period-income-categories-pie-chart')}}</b-dropdown-item>
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-item @click="toggleChart('period-expense-bar-chart')" v-bind:class="{ active: (showChart === 'period-expense-bar-chart') }">{{$t(translationPath + 'charts.period-expense-bar-chart')}}</b-dropdown-item>
                    <b-dropdown-item @click="toggleChart('period-expense-groups-pie-chart')" v-bind:class="{ active: (showChart === 'period-expense-groups-pie-chart') }">{{$t(translationPath + 'charts.period-expense-groups-pie-chart')}}</b-dropdown-item>
                    <b-dropdown-item @click="toggleChart('period-expense-categories-pie-chart')" v-bind:class="{ active: (showChart === 'period-expense-categories-pie-chart') }">{{$t(translationPath + 'charts.period-expense-categories-pie-chart')}}</b-dropdown-item>
                  </b-dropdown>

                  <toggle-budgets-dropdown v-if="budgetId" :budgets="budgets" :initial-budget-id="budgetId" @change="onBudgetChange" />
                </b-col>
              </b-row>
            </template>

            <div class="col-lg-8 pl-0" v-html="$t(translationPath + 'description_' + tableType)"></div>

            <b-table
              class="spirecta-simple-table performance-over-time-table mb-0"
              show-empty
              hover
              responsive
              striped
              stacked="md"
              :items="tableData"
              :fields="aTableColumns"
              :busy="!isLoaded"
              :tbodyTrClass="trClass"
              :filter="sFilter"
              :filter-included-fields="['label']"
              :emptyFilteredText ="$t('common.no_filter_result')"
            >
              <template v-slot:table-busy><loader/></template>

              <!-- CUSTOM SLOT: FILTER -->
              <template slot="top-row">
                <td :colspan="aTableColumns.length">
                  <div class="d-flex align-items-center">
                    <i class="fa fa-search text-secondary"></i>
                    <b-form-input v-model="sFilter" size="sm" :placeholder="$t('common.filter_placeholder')"/>
                  </div>
                </td>
              </template>

              <!-- CUSTOM SLOTS -->
              <template v-slot:cell(label)="row">
                <template v-if="row.item.row_type === 'group_heading'"></template>
                <template v-else-if="row.item.row_type === 'account'">
                  <b-link :to="'/reports/performance/accounts/'+row.item.id+'/view/summary?account_type='+row.item.type+'&start_date='+apiData.meta.start_date+'&end_date='+apiData.meta.end_date">{{ row.value }}</b-link>
                </template>
                <template v-else-if="row.item.row_type === 'group'">
                  <b-link href="#" @click="(e) => onGroupTitleClick(e, row.item.type)">{{ row.value }}</b-link>
                </template>
                <template v-else>{{ row.value }}</template>
              </template>

              <!-- Period slots -->
              <template v-slot:[gomycell(sPeriod)]="row" v-for="sPeriod in isLoaded ? this.apiData.meta.periods : []">
                <template v-if="row.item.row_type === 'empty' || row.item.row_type === 'group-heading'"></template>
                <template v-else-if="row.item.row_type === 'account'">
                  <b-link :to="'/budget/account/'+row.item.id + '/' + (''+sPeriod).substr(0,4).toString()" v-bind:key="sPeriod">{{ row.value }}</b-link>
                </template>
                <template v-else-if="row.item.row_type === 'type_heading'">{{row.unformatted}}</template>
                <template v-else>{{row.value}}</template>
              </template>

              <!-- total slot-->
              <template v-slot:cell(total)="row">
                <template v-if="row.item.row_type === 'empty' || row.item.row_type === 'group-heading'"></template>
                <template v-else>{{row.value}}</template>
              </template>

              <!-- average slot-->
              <template v-slot:cell(avg)="row">
                <template v-if="row.item.row_type === 'empty' || row.item.row_type === 'group-heading'"></template>
                <template v-else>{{row.value}}</template>
              </template>

              <!-- END CUSTOM SLOTS -->
            </b-table>
          </b-card>
        </b-col>
      </b-row>
    </b-container>

  </div>
</template>

<script>
/* eslint-disable camelcase */
import { mapState } from 'vuex'
import Loader from '@/components/common/Loader'
import formatAmount from '@/assets/filters/formatAmount'
import SpirectaReportTableMixin from '@/views/reports/_inc/SpirectaReportTableMixin'
import ToggleBudgetsDropdown from '@/components/common/ToggleBudgetsDropdown'

export default {
  name: 'PerformanceBudgetReportPeriodTable',
  mixins: [SpirectaReportTableMixin],
  components: { Loader, ToggleBudgetsDropdown },
  filters: {
    formatAmount
  },
  props: {
    startDate: { type: String, default: null },
    endDate: { type: String, default: null },
    isLoaded: { type: Boolean, default: false },
    tableType: { type: String, default: 'summary' },
    resolution: { type: String, default: 'month' },
    options: {
      type: Object,
      default: function () {
        return {}
      }
    },
    apiData: {
      type: Object,
      default: function () {
        return {
          periods: []
        }
      }
    },
    budgets: {
      type: Array,
      default: function () {
        return []
      }
    },
    budgetId: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      translationPath: 'reports.budget.performance.',
      tableData: [],
      bShowAccTableRow: false,
      showChart: 'period-result-bar-chart',
      sFilter: null
    }
  },
  computed: {
    ...mapState('user', ['currentUser', 'currentCOA']),
    aTableColumns () {
      if (!this.isLoaded) { return [] }
      const aColumns = [{ key: 'label', label: this.$t('common.incomes'), sortable: false, class: 'text-left td-label' }]

      for (const sPeriod in this.apiData.meta.periods) {
        aColumns.push({ key: '' + this.apiData.meta.periods[sPeriod], label: this.translatePeriodColumnTitleIntoNiceName(this.apiData.meta.periods[sPeriod], this.resolution).toString(), sortable: false, class: 'text-left text-md-right td-period', formatter: (value) => { return this.amountFormatter(value, false, 0) } })
      }

      aColumns.push({ key: 'total', label: this.$t('common.total'), class: 'td-total text-left text-md-right', formatter: (value) => { return this.amountFormatter(value, false, 0) } })
      aColumns.push({ key: 'avg', label: this.$t(this.translationPath + 'average_per_month'), class: 'td-average text-left text-md-right', formatter: (value) => { return this.amountFormatter(value, false, 0) } })

      return aColumns
    }
  },
  methods: {
    toggleChart (showChart) {
      this.showChart = showChart
      this.$emit('chart-to-display', { chart: showChart })
    },
    buildTableDataRows () {
      if (!this.isLoaded) { return [] }
      this.tableData = []

      switch (this.tableType) {
        case 'groups_performance' :
          this.tableData = this.buildGroupPerformanceTable()
          break
        case 'accounts_performance' :
          this.tableData = this.buildAccountsPerformanceTable()
          break
        case 'income' :
          this.tableData = this.buildAccountsTypeTable('income')
          break
        case 'expense' :
          this.tableData = this.buildAccountsTypeTable('expense')
          break
      }
      return this.tableData
    },
    buildAccountsTypeTable (sType) {
      // Group
      const aOutput = []
      for (const idx in this.apiData[sType].groups) {
        const oGroup = this.apiData[sType].groups[idx]
        aOutput.push({ label: oGroup.label, row_type: 'group-heading' })

        // Account
        for (const iAcctIdx in oGroup.accounts) {
          const oAccount = oGroup.accounts[iAcctIdx]

          const oAcctRow = { label: oAccount.label, row_type: 'account', id: oAccount.id, type: sType }
          this.apiData.meta.periods.map(periodIdx => {
            oAcctRow[periodIdx] = oAccount.periods[periodIdx]
          })
          oAcctRow.total = oAccount.total.sum
          oAcctRow.avg = oAccount.total.avg
          aOutput.push(oAcctRow)
        }

        // Subtotal
        const oGroupSubtotal = { label: this.$t('common.subtotal') + ' (' + oGroup.label.toLowerCase() + ')', row_type: 'subtotal' }
        this.apiData.meta.periods.map(periodIdx => {
          oGroupSubtotal[periodIdx] = oGroup.periods[periodIdx]
        })
        oGroupSubtotal.total = oGroup.total.sum
        oGroupSubtotal.avg = oGroup.total.avg
        aOutput.push(oGroupSubtotal)

        aOutput.push({ row_type: 'empty' })
      }

      const oTotal = { label: this.$t('common.total') + ' (' + this.$t('common.' + sType + 's').toLowerCase() + ')', row_type: 'total' }
      this.apiData.meta.periods.map(periodIdx => {
        oTotal[periodIdx] = this.apiData[sType].periods[periodIdx]
      })
      oTotal.total = this.apiData[sType].total.sum
      oTotal.avg = this.apiData[sType].total.avg
      aOutput.push(oTotal)

      return aOutput
    },
    buildAccountsPerformanceTable () {
      const aTypes = ['income', 'expense']
      const aOutput = []
      let bFirst = true

      for (const typeIdx in aTypes) {
        const sType = aTypes[typeIdx]

        if (!bFirst) {
          const oTypeHeading = { label: this.$t('common.' + sType + 's'), row_type: 'type_heading' }

          for (const periodIdx in this.apiData.meta.periods) {
            oTypeHeading[this.apiData.meta.periods[periodIdx]] = this.translatePeriodColumnTitleIntoNiceName(this.apiData.meta.periods[periodIdx], this.resolution).toString()
          }
          oTypeHeading.total = this.$t('common.total')
          aOutput.push(oTypeHeading)
        }
        bFirst = false

        // Type total
        aOutput.concat(this.buildAccountsTypeTable(sType))
      }

      return aOutput
    },
    buildGroupPerformanceTable () {
      /**
       * Income and expense groups
       */
      const aTypes = ['income', 'expense']
      let bFirst = true
      for (const typeIdx in aTypes) {
        const sType = aTypes[typeIdx]

        if (!bFirst) {
          const oTypeHeading = { label: this.$t('common.' + sType + 's'), row_type: 'type_heading' }

          for (const periodIdx in this.apiData.meta.periods) {
            oTypeHeading[this.apiData.meta.periods[periodIdx]] = this.translatePeriodColumnTitleIntoNiceName(this.apiData.meta.periods[periodIdx], this.resolution).toString()
          }
          oTypeHeading.total = this.$t('common.total')
          oTypeHeading.avg = this.$t(this.translationPath + 'average_per_month')
          this.tableData.push(oTypeHeading)
        }
        bFirst = false

        for (const idx in this.apiData[sType].groups) {
          const oGroup = this.apiData[sType].groups[idx]

          const oRow = { label: oGroup.label, row_type: 'group' }
          for (const periodIdx in this.apiData.meta.periods) {
            oRow[this.apiData.meta.periods[periodIdx]] = oGroup.periods[this.apiData.meta.periods[periodIdx]]
          }
          oRow.total = oGroup.total.sum
          oRow.avg = oGroup.total.avg
          oRow.type = sType

          this.tableData.push(oRow)
        }

        // Subtotal
        const oSubtotal = { label: this.$t('common.subtotal_' + sType), total: this.apiData[sType].total.sum, row_type: 'subtotal', avg: this.apiData[sType].total.avg }
        for (const periodIdx in this.apiData.meta.periods) {
          const sPeriod = this.apiData.meta.periods[periodIdx]
          oSubtotal[sPeriod] = this.apiData[sType].periods[sPeriod]
        }
        this.tableData.push(oSubtotal)

        this.tableData.push({ row_type: 'empty' })
      }

      // Total
      const oTotal = { label: this.$t('common.result'), total: this.apiData.total.sum, avg: this.apiData.total.avg, row_type: 'total' }
      for (const periodIdx in this.apiData.meta.periods) {
        const sPeriod = this.apiData.meta.periods[periodIdx]
        oTotal[sPeriod] = this.apiData.total.periods[sPeriod]
      }
      this.tableData.push(oTotal)

      return this.tableData
    },
    onGroupTitleClick (e, type) {
      e.preventDefault()
      this.$emit('on-group-click', { type: type })
    },
    onBudgetChange (budgetId) {
      this.$emit('on-budget-change', budgetId)
    },
    $changeChart (chart) {
      this.showChart = chart
    }
  },
  watch: {
    apiData: {
      handler: 'buildTableDataRows',
      deep: true,
      immediate: true
    },
    tableType: {
      handler: 'buildTableDataRows',
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss">
.component-performance-budget-report-period-table{
  tr.tr-group-heading td,
  tr.tr-type-heading td{
    font-weight: 700;
    border-top:0;
    background: white;
  }

  td.td-total{
    border-left: 1px solid black;
  }
  tr.tr-group-heading td.td-total,
  tr.tr-empty td.td-total{
    border-left: 0;
  }
}
</style>
