<template>
  <b-container fluid class="component-reports-performance-budget-report-pie-chart">
    <pie-chart
      :chart-data="this.chartData"
      :options="this.chartOptions"
      :chart-id="'reports-performance-budget-report-pie-chart'"
      :width="100"
      :height="400"
      :css-classes="''"
      :plugins="[]"
    >
    </pie-chart>
  </b-container>
</template>

<script>
import PieChart from '@/components/chartjs/DoughnutChart'
import chartOptions from '@/mixins/defaultChartJsOptions'
import formatNumberToFull from '@/assets/filters/formatNumberToFull'
import SpirectaReportChartMixin from '@/views/reports/_inc/SpirectaReportChartMixin'

export default {
  name: 'PerformanceBudgetReportPieChart',
  components: { PieChart },
  mixins: [chartOptions, SpirectaReportChartMixin],
  props: {
    apiData: { type: Object },
    account: { type: Object },
    resolution: { type: String },
    chartToDisplay: { type: String, default: 'period-income-categories-pie-chart' },
    isLoaded: { type: Boolean, default: false }
  },
  data () {
    return {
      chartData: {},
      translationPath: 'reports.budget.performance.charts.'
    }
  },
  methods: {
    fillChart () {
      if (!this.isLoaded) {
        return false
      }

      const labels = []
      const dataPeriod = []
      const aColors = this.getChartPalette()
      const currencySymbol = this.currencySymbol ? this.currencySymbol : 'kr'

      /* Modify the Y-Axis */
      this.chartOptions.scales.yAxes[0].display = false
      this.chartOptions.scales.xAxes[0].display = false
      this.chartOptions.legend.display = true

      /* Make half circle */
      this.chartOptions.rotation = -Math.PI
      this.chartOptions.circumference = Math.PI

      /* Tooltip */
      /* Modify the tooltip onmoauseover */
      this.chartOptions.tooltips.callbacks.label = function (tooltipItem, chartData) {
        return chartData.labels[tooltipItem.index] + ': ' + formatNumberToFull(chartData.datasets[0].data[tooltipItem.index].toFixed(2)) + ' ' + currencySymbol
      }

      /* Prepare data */
      const sGroupType = (this.chartToDisplay === 'period-income-categories-pie-chart' || this.chartToDisplay === 'period-income-groups-pie-chart') ? 'income' : 'expense'
      const iTotal = parseFloat(this.apiData[sGroupType].total.sum)

      /**
       * Calculate data for groups
       */
      if ((this.chartToDisplay === 'period-income-groups-pie-chart' || this.chartToDisplay === 'period-expense-groups-pie-chart')) {
        // Sort largest first
        const aSortedData = this.apiData[sGroupType].groups.filter((item) => item)
        aSortedData.sort((a, b) => parseFloat(a.total.sum) >= parseFloat(b.total.sum) ? -1 : 1)

        for (const iGroupIdx in aSortedData) {
          const oGroup = aSortedData[iGroupIdx]
          const iGroupSum = parseFloat(oGroup.total.sum)

          if (iGroupSum !== 0) {
            const iPercentage = (Math.round(iGroupSum / iTotal * 100)).toFixed(0)
            labels.push(oGroup.label + ' (' + iPercentage + '%)')
            dataPeriod.push(iGroupSum)
          }
        }
      }

      /**
       * Calculate data for accounts
       */
      if (this.chartToDisplay === 'period-income-categories-pie-chart' || this.chartToDisplay === 'period-expense-categories-pie-chart') {
        const aSortedData = []

        for (const iGroupIdx in this.apiData[sGroupType].groups) {
          const oGroup = this.apiData[sGroupType].groups[iGroupIdx]
          for (const iAccountIdx in oGroup.accounts) {
            const oAccount = oGroup.accounts[iAccountIdx]
            aSortedData.push({ label: oAccount.label, group_label: oGroup.label, sum: parseFloat(oAccount.total.sum) })
          }
        }
        aSortedData.sort((a, b) => parseFloat(a.sum) >= parseFloat(b.sum) ? -1 : 1)

        for (const iAccountIdx in aSortedData) {
          const oAccount = aSortedData[iAccountIdx]

          if (oAccount.sum !== 0) {
            const iPercentage = (Math.round(oAccount.sum / iTotal * 100)).toFixed(0)
            labels.push(oAccount.label + ' (' + oAccount.group_label + '), ' + iPercentage + '%')
            dataPeriod.push(oAccount.sum)
          }
        }
      }

      this.chartData = {
        labels: labels,
        datasets: [{
          label: this.$t(this.translationPath + 'period'),
          data: dataPeriod,
          backgroundColor: aColors
        }]
      }
    }
  },
  watch: {
    isLoaded: {
      handler: 'fillChart',
      deep: true,
      immediate: true
    },
    chartToDisplay: {
      handler: 'fillChart',
      deep: true,
      immediate: true
    }
  }
}
</script>
