<template>
  <b-container fluid class="component-reports-budget-performance-line-chart pb-5">
    <line-chart
      :chart-data="this.chartData"
      :options="this.chartOptions"
      :chart-id="'reports-budget-performance-line-chart'"
      :width="100"
      :height="400"
      :css-classes="''"
      :plugins="[]"
    >
    </line-chart>
  </b-container>
</template>

<script>
import LineChart from '@/components/chartjs/LineChart'
import chartOptions from '@/mixins/defaultChartJsOptions'
import SpirectaReportChartMixin from '@/views/reports/_inc/SpirectaReportChartMixin'

export default {
  name: 'PerformanceBudgetReportLineChart',
  props: {
    apiData: { type: Object },
    account: { type: Object },
    resolution: { type: String },
    isLoaded: { type: Boolean, default: false }
  },
  mixins: [chartOptions, SpirectaReportChartMixin],
  components: {
    LineChart
  },
  data () {
    return {
      translationPath: 'reports.budget.performance.charts.',
      chartData: {},
      currencySuffix: ''
    }
  },
  watch: {
    isLoaded: {
      handler: 'fillChart',
      deep: true,
      immediate: true
    }
  },
  methods: {
    fillChart () {
      if (!this.isLoaded) {
        return false
      }

      if (Object.keys(this.apiData).length > 0) {
        this.chartData = {}
      }

      // const suffix = ' ' + this.currencySuffix

      const labels = []
      const budgetAmounts = []
      const apiPeriods = this.apiData.total.periods

      let iBudgetAcc = 0

      this.apiData.meta.periods.map(period => {
        labels.push(this.translatePeriodColumnTitleIntoNiceName(period, this.resolution))

        iBudgetAcc += parseInt(apiPeriods[period])

        budgetAmounts.push(iBudgetAcc)
      })

      this.chartData = {
        labels: labels,
        datasets: [
          {
            label: this.$t(this.translationPath + 'budget_acc'),
            data: budgetAmounts,
            fill: false,
            borderColor: this.getGreen()
          }
        ]
      }
    }
  }
}
</script>
